<template>
  <section class="loginfrm ftco-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12 col-lg-10">
          <div class="wrap d-md-flex">
            <div class="text-wrap p-4 p-lg-5 text-center d-flex align-items-center order-md-last">
              <div class="text w-100 anivertilogo">
                <h1>경농 EHS Partner</h1>
                <p>안전의견(외부인력)</p>
              </div>
            </div>
            <div class="login-wrap p-4 p-lg-5">
              <div class="d-flex">
                <div class="w-100">
                  <h3 class="mb-4">Log In</h3>
                </div>
                <div class="w-100">
                </div>
              </div>
              <q-form @submit="onSubmit" class="signin-form">
                <div class="form-group mb-3">
                  <q-input ref="userIdInput" class="loginText" rounded outlined color="login-main-color" filled v-model="loginForm.userId" placeholder="협력업체 아이디(사업자 번호 or 아이디)" name="userId" required>
                    <template v-slot:prepend>
                      <q-icon name="person" />
                    </template>
                    <template v-slot:append>
                      <q-icon name="close" @click="loginForm.userId = ''" class="cursor-pointer" />
                    </template>
                  </q-input>
                </div>
                <div class="form-group mb-3">
                  <q-input class="loginText" :type="isPwd ? 'password' : 'text'" rounded outlined color="login-main-color" filled v-model="loginForm.password" placeholder="협력업체 비밀번호" name="password" required>
                    <template v-slot:prepend>
                      <q-icon name="password" />
                    </template>
                    <template v-slot:append>
                      <q-icon
                        :name="isPwd ? 'visibility_off' : 'visibility'"
                        class="cursor-pointer"
                        @click="isPwd = !isPwd"
                      />
                    </template>
                  </q-input>
                </div>
                <div class="form-group">
                  <q-btn
                    :loading="loading"
                    rounded
                    class="loginbtn"
                    size="lg"
                    icon="lock_open"
                    color="login-main-color"
                    style="width: 100%"
                    type="submit"
                  > Sign In
                    <template v-slot:loading>
                      <q-spinner-gears class="on-left" />
                      Sign In...
                    </template>
                  </q-btn>
                </div>
              </q-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </section>
</template>

<script>
import Cookies from 'js-cookie'
import { encrypt } from '@/js/aes256';
export default {
  name: 'login',
  data: () => ({
    checkDomain: false,
    rememberMe: false,
    isPwd: true,
    slide: 'first',
    loginForm: {
      userId: '',
      password: '',
    },
    loading: false,
    // redirect: null,
    popupOptions: {
      target: null,
      title: '',
      visible: false,
      width: '40%',
      isFull: true,
      param: {},
      closeCallback: null,
    },
  }),
  watch: {
    // $route: {
    //   handler: function(route) {
    //     this.redirect = route.query && route.query.redirect;
    //   },
    //   immediate: true,
    // },
  },
  beforeMount() {
    let lang = Cookies.get('language') ? Cookies.get('language') : 'kr';
    this.$store.dispatch('setLanguage', lang)
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$store.dispatch('LogOut').then(() => {
        window.localStorage.removeItem('defaultPlantCd');
      })  
    },
    onSubmit () {
      this.loading = true;
      let promises = [
        // { func: this.checkValid, exceptionMessage: 'M0000000001' }, /** id, pwd 입력여부 */
        { func: this.actionLogin }, /** login api 호출 */
        { func: this.getLangInfo }, /** 언어별 라벨, 메시지 정보 get */
      ];
      this.$comm.orderedPromise(promises);
    },
    actionLogin() {
      return new Promise((_resolve, _reject) => {
        if (this.rememberMe === true) {
          window.localStorage.setItem('rememberLoginId', encrypt(this.loginForm.userId));
        } else {
          window.localStorage.removeItem('rememberLoginId');
        }
        this.loading = true;
        this.$store
          .dispatch('LoginByUserAccount', {
            userId: encrypt(this.loginForm.userId),
            password: encrypt(this.loginForm.password),
          })
          .then(() => {
            _resolve(true);
          })
          .catch(error => {
            if (error && error.response.data.returnCode === 'UNDER_CONSTRUCTION') {
              // 개발서버 접속 못하게 설정하였을 경우
              this.$commMessage.alert(this, {
                title: '알림',
                message: error.response.data.message,
                type: 'info',
              });
            } else {
              this.$commMessage.alert(this, {
                title: '에러', // 에러
                message: error.response.data.message,
                type: 'error',
              });
            }
            this.loading = false;
            _reject(error);
          });
      })
    },
    getLangInfo(divide) {
      return new Promise((_resolve, _reject) => {
        this.$store
          .dispatch('GetLangInfo', { lang: Cookies.get('language'), divide: divide })
          .then((hasLang) => {
            if (hasLang) {
              if (!divide) {
                this.getDetail();
                // this.$router.push({ path: this.redirect || '/' });
              }
            }
            this.loading = false;
            _resolve(hasLang);
          })
          .catch(error => {
            if (error && error.message === 'Network Error') {
              this.$commMessage.alert(this, {
                title: error.message,
                message: '서버에 연결할 수 없습니다. 관리자에게 문의바랍니다.',
                type: 'info',
              });
            } else {
              this.$commMessage.alert(this, {
                title: '로그인 에러', // 로그인 에러
                message: '로그인 진행중 에러가 발생하였습니다. 관리자에게 문의바랍니다.', // 로그인 진행중 에러가 발생하였습니다. 관리자에게 문의바랍니다.
                type: 'info',
              });
              if (!divide) {
                this.$store
                  .dispatch('LogOut')
                  .then(() => {
                  })
                  .catch(() => {
                  });
              }
              this.loading = false;
            }
            _reject();
          });
      });
    }, 
    getDetail() {
      this.popupOptions.title = '안전의견(외부)';
      this.popupOptions.param = {
        heaInvestigationPlanId: this.$route.query.heaInvestigationPlanId,
      };
      this.popupOptions.target = () => import(`${'./safOpinionBoardDetailOuterPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
<style lang="scss">
.loginfrm {
  section {
    display: block;
  }
  .justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .text-center {
    text-align: center !important;
  }
  .mb-5, .my-5 {
    margin-bottom: 3rem !important;
  }
  .col-md-6 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .wrap {
    border-radius: 15px;
    width: 100%;
    -webkit-box-shadow: 27px 43px 43px -26px rgba(89,89,89,0.39);
    -moz-box-shadow: 27px 43px 43px -26px rgba(89,89,89,0.39);
    box-shadow: 27px 43px 43px -26px rgba(89,89,89,0.39);
  }
  .align-items-center {
    -webkit-box-align: center !important;
    align-items: center !important;
  }
  .d-flex {
    display: flex !important;
  }
  .w-100 {
    width: 100% !important;
  } 
  .text-wrap .text h2 {
    font-weight: 900;
    color: #fff;
    line-height: 1.5;
    font-size: 2rem;
    margin-bottom: .5rem;
    margin-top: 0;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .login-wrap {
    position: relative;
    background: #fff;
    width: 50%;
    border-radius: 15px 0 0 15px;
    h3 {
      font-weight: 300;
      line-height: 1.5;
      color: #000;
      font-size: 1.75rem;
      margin-top: 0;
    }
  }
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  form {
    display: block;
    margin-top: 0em;
  }
  .form-group {
    position: relative;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  .loginText {
    .q-field__control {
      border-radius: 20px;
      background: #f2f2f2;
    }
    .q-field__prepend {
      padding-right: 10px !important;
    } 
    .q-field__control-container input.q-field__native {
      margin-bottom: 0px !important;
    }
    .q-field--float {
      .q-field__label {
        transform: translateY(-70%) scale(0.75) !important;
      }
    }
  }
  .q-btn__content {
    .q-icon {
      font-size: 1.5rem;
      margin-right: 10px;
    }
  }
  .inline-btn {
    display: inline-flex;
  }

  .col-md-12 {
    position: relative;
    width: 100%;
  }
}
@media (min-width: 576px) {
  .loginfrm .container {
    max-width: 570px;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  .loginfrm .container {
    max-width: 760px;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 992px) {
  .loginfrm.ftco-section {
    position: absolute;
    left: 50%;
    top: 40%;
    width: 960px;
    transform: translate(-50%,-40%);
  }
  .loginfrm .container {
    max-width: 960px;
  }
  .loginfrm .col-lg-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .loginfrm .p-lg-5 {
    padding: 3rem! important;
  }
  .loginfrm .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .loginfrm .col-md-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .loginfrm .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .loginfrm .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
}
@media (min-width: 1200px) {
  .loginfrm.ftco-section {
    position: absolute;
    left: 50%;
    top: 40%;
    width: 960px;
    transform: translate(-50%,-40%);
  }
  .loginfrm .container {
    max-width: 960px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 991.98px) {
  .loginfrm .text-wrap, .loginfrm .login-wrap {
    width: 100%;
  }
  .text-wrap {
    border-radius: 15px 15px 0 0 !important;
  }
  .login-wrap {
    border-radius: 0 0 15px 15px !important;
  }
}
</style>